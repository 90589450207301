.inputField {
  width: 100%;
  font-size: 20px;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(216, 25, 255);
  background-color: transparent;
  color: white;
  text-align: start;
}

::-webkit-calendar-picker-indicator {
  background-color: rgb(216, 25, 255);
  border-radius: 5px;
  font-size: 16px;
  position: relative;
}

.nonUsTitle {
  text-align: center;
  margin-top: 15px;
  color: white;
}
.nonUsHeading {
  margin-top: 15px;
  color: white;
  text-align: center;
  font-weight: 150px;
  font-size: 20px;
}

@media screen and (max-width: 600px) {
  .nonUsHeading {
    font-size: 16px !important;
  }
}

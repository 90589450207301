.container1 {
  width: 100%;
  margin: auto;
}
.titleMainContainer {
  color: white;
  text-align: center;
  margin: 20px;
}

.termLink {
  color: rgb(216, 25, 255);
  font-weight: 300;
}
.termLink:hover {
  color: rgb(144, 21, 168) !important;
}

.TermsButton {
  width: 100%;
  height: 50px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 15px;
  border-radius: 5px;
  font-weight: bold;
}
.headerContainer {
  color: white;
  text-align: center;
  margin: 10px;
}
.termsContainer {
  margin-top: 15px;
  color: white;
  font-size: 20px;
  background-color: #06205b;
  line-height: 22px;
}
.inputsMainContainer {
  margin-top: 20px;
}
.successLogo {
  color: white;
  text-align: center;
  margin: 20px;
}
.inputContainer {
  padding: 10px 0px;
}
.titleContainerSuccess {
  color: white;
  text-align: center;
  margin: 20px;
}
.inputFields {
  font-size: 30px;
  background-color: rgb(37, 53, 111);
  border: none;
  outline: none;
  text-align: center;
  color: white !important;
  border-radius: 5px 5px 0px 0px;
  margin: 10px;
  width: 50px !important;
}
.continueBtn {
  padding-top: 60px;
}

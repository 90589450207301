.mainContainer {
  padding: 10px;
  margin-top: 15px;
  color: white;
  font-size: 20px;
  background-color: #06205b;
}
.inputContainer {
  display: flex;
  align-items: center;
}
.input {
  margin: 10px;
  text-align: center;
}
.labelText {
  font-size: 16px;
}

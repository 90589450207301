.container {
  padding: 0px;
  width: 50% !important;
  margin-top: 70px;
}
.container1 {
  padding: 0px;
  width: 40% !important;
  margin-top: 70px !important ;
}

.mainTitle {
  color: white;
  padding: 10px;
  font-weight: bold;
  font-size: 28px;
}
.button {
  padding: 20px;
  margin: 10px;
}
.buttonContinue {
  width: 100%;
  height: 50px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
.disableButton {
  width: 100%;
  height: 50px;
  background-color: #051d56;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
i {
  margin-right: 5px;
}
.button-cont-text {
  padding-top: 10px;
}
.mainHeader {
  text-align: center;
}
.title {
  color: white;
  text-align: start;
  font-size: 26px !important;
  font-weight: 300 !important;
}
.titleNumber {
  text-align: start;
  color: lightgray;
}
@media screen and (max-width: 1800px) {
  .container {
    width: 65% !important;
  }
}
@media screen and (max-width: 1600px) {
  .container {
    width: 70% !important;
  }
}
@media screen and (max-width: 1400px) {
  .container {
    width: 70% !important;
  }
  .title {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    width: 80% !important;
  }
  .title {
    font-size: 24px !important;
  }
  .container1 {
    width: 45% !important;
  }
}
@media screen and (max-width: 1000px) {
  .container {
    width: 90% !important;
  }
  .buttonContinue {
    height: 45px;
    padding: 5px;
  }
  .disableButton {
    height: 45px;
    padding: 5px;
  }
  .title {
    font-size: 22px !important;
  }
  .container1 {
    width: 55% !important;
  }
}
@media screen and (max-width: 800px) {
  .container {
    width: 90% !important;
  }
  .title {
    font-size: 22px !important;
  }
  .container1 {
    width: 65% !important;
  }
}
@media screen and (max-width: 600px) {
  .container {
    width: 100% !important;
  }
  .title {
    font-size: 20px !important;
  }
  .container1 {
    width: 75% !important;
  }
}
@media screen and (max-width: 400px) {
  .container {
    width: 100% !important;
  }
  .container1 {
    width: 85% !important;
  }
}
@media screen and (max-width: 300px) {
  .container {
    width: 100% !important;
  }
  .container1 {
    width: 95% !important;
  }
}
@media screen and (max-width: 576px) {
  .container {
    margin-top: 40px;
  }
}
